@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap");

.chatPage-body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #414752;
  min-width: 320px;
  background: #f4f5f7;
  font-family: "Poppins", sans-serif;
  line-height: 1.4;
  font-weight: 400;
  overflow-x: hidden;
}

.chatAI {
  background: #fff;
  width: 100%;
  height: 100%;

  .chatAI-wrap {
    height: 100%;
    width: 100%;

    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-content: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    align-items: stretch;

    >.chatAI-chat-wrap {
      -webkit-order: 0;
      order: 0;
      -webkit-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-align-self: auto;
      align-self: auto;

      padding: 0;
      margin: 0;

      overflow: hidden;
      position: relative;

      height: calc(100% - 54px);
      max-height: calc(100% - 54px);

      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      -webkit-align-content: flex-end;
      align-content: flex-end;
      -webkit-align-items: stretch;
      align-items: stretch;

      .chatAI-form-wrap {
        -webkit-order: 0;
        order: 0;
        -webkit-flex: 0 1 auto;
        flex: 0 1 auto;
        -webkit-align-self: auto;
        align-self: auto;
        height: 55px;
        max-height: 55px;
      }

    }
  }

  .chatAI-chat {
    position: relative;
    width: 100%;

    overflow: auto;
    padding: 16px;

    -webkit-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    align-self: auto;

    height: auto;
    max-height: 100%;
  }

  // --

  .chatAI-form-wrap {
    padding: 0;
    margin: 0;
    border-top: 1px solid #d5d7db;

    .chatAI-form {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-align-content: stretch;
      align-content: stretch;
      -webkit-align-items: stretch;
      align-items: stretch;
      margin: 0;
      padding: 0;
      position: relative;

      input {
        height: 54px;
        color: #0e0e0f;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        width: 100%;
        font-weight: 400;
        line-height: 22px;
        overflow: hidden;
        padding: 16px 60px 16px 16px;
        border: 0 none;
        border-radius: 0;
        outline: none;
        box-shadow: none;

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }

      .chatAI-form-btn {
        cursor: pointer;
        position: absolute;
        padding: 0;
        font-size: 0;
        line-height: 0;
        color: transparent;
        white-space: nowrap;
        text-indent: 100%;
        opacity: 1;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin: -20px 0 0;
        top: 50%;
        right: 10px;
        background: transparent url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12L3.2343 4.6248C3.09161 4.2443 3.18449 3.81551 3.47184 3.52816V3.52816C3.78725 3.21275 4.26911 3.13455 4.66807 3.33403L22 12L4.66807 20.666C4.2691 20.8654 3.78725 20.7873 3.47184 20.4718V20.4718C3.18449 20.1845 3.09161 19.7557 3.2343 19.3752L6 12ZM6 12H13' stroke='rgba(37, 37, 51, 0.5)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
        transition: all 0.3s ease-in;
        z-index: 1;
        border: 0 none;
        outline: none;

        &::hover {
          opacity: 0.6;
        }

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }

      input:active+.chatAI-form-btn,
      input:focus+.chatAI-form-btn {
        background: #0f62fe url("data:image/svg+xml,%0A%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12L3.2343 4.6248C3.09161 4.2443 3.18449 3.81551 3.47184 3.52816V3.52816C3.78725 3.21275 4.26911 3.13455 4.66807 3.33403L22 12L4.66807 20.666C4.2691 20.8654 3.78725 20.7873 3.47184 20.4718V20.4718C3.18449 20.1845 3.09161 19.7557 3.2343 19.3752L6 12ZM6 12H13' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center;
      }
    }
  }

  // ---

  .chatAI-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-align-content: flex-end;
    align-content: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
    gap: 8px;

    padding: 0 0 0 40px;
    margin: 16px 0;

    >div {
      -webkit-order: 0;
      order: 0;
      -webkit-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-align-self: auto;
      align-self: auto;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .chatAI-message-wrap {
      width: 100%;

      .chatAI-message {
        border-radius: 9px 9px 0px 9px;
        background: #0f62fe;
        padding: 10px 16px;
        font-size: 14px;
        line-height: 1.4;
        color: #fff;
        white-space: normal;
        word-break: break-word;

        >div {
          overflow: hidden;
          width: 100%;
          margin: 0;
          padding: 0;
        }

        .wrapper-text-typing {
          margin: 0;
          width: 100%;
          overflow: hidden;
        }

        .wrapper-helpful {
          margin: 14px 0;
          padding: 10px 0 2px;
          border-top: 1px solid rgba($black, 0.125);

          p {
            margin-bottom: 8px;
          }

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          >div {
            margin: 8px 0;
            position: relative;

            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            flex-direction: row;
            -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
            -webkit-justify-content: flex-end;
            justify-content: flex-end;
            -webkit-align-content: center;
            align-content: center;
            -webkit-align-items: center;
            align-items: center;

            &:first-child {
              margin-top: 0;
            }

            &:last-child {
              margin-bottom: 0;
            }

            >div {
              -webkit-order: 0;
              order: 0;
              -webkit-flex: 0 1 auto;
              flex: 0 1 auto;
              -webkit-align-self: auto;
              align-self: auto;
              width: auto;
              max-width: 50%;
              padding: 0 4px;
              margin: 0;

              button {
                min-width: 36px;
                min-height: 36px;
                padding: 9px;
                > span {
                  content: '';
                  display: block;
                  width: 16px;
                  height: 16px;
                  margin: 0;
                  padding: 0;
                  overflow: hidden;
                }

                &:disabled {
                  opacity: 0.75;
                }
              }

              &:nth-child(1) {
                button {
                  > span {
                    background: transparent url('../svg/likeIcon.svg') no-repeat scroll 50% 50%;
                    background-size: 16px 16px;
                  }
                }
              }

              &:nth-child(2) {
                button {
                  > span {
                    background: transparent url('../svg/dislikeIcon.svg') no-repeat scroll 50% 50%;
                    background-size: 16px 16px;                    
                  }
                }
              }
            }

            .chatAI-like-button {
              &.like {
                background-color: #dcf2dc;
              }
  
              &.dislike {
                background-color: #fae1e5;
              }
            }
          }
        }
      }
    }

    .time {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      color: #70747a;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.4;

      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      -webkit-align-content: center;
      align-content: center;
      -webkit-align-items: center;
      align-items: center;
      gap: 4px;

      >span {
        padding: 4px 0 0;
        display: inline-block;
        vertical-align: top;
        width: auto;
        max-width: 100%;
      }
    }

    .chatAI-avatar-bot {
      display: none;
    }

    &.bot {
      padding: 0 40px 0 0;

      .chatAI-message {
        border-radius: 9px 9px 9px 0px;
        background: #ebecf0;
        color: #0e0e0f;
      }

      .time {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-align-content: center;
        align-content: center;
        -webkit-align-items: center;
        align-items: center;
      }

      .chatAI-avatar-bot {
        margin: 0;
        padding: 0;
        display: block;
        overflow: hidden;
        width: 32px;
        height: 32px;
        background: url("data:image/svg+xml,%0A%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7 4C5.34315 4 4 5.34315 4 7V25C4 26.6569 5.34315 28 7 28H25C26.6569 28 28 26.6569 28 25V7C28 5.34315 26.6569 4 25 4H7ZM24 10V11C23 11 22 12 22 13H21C21 12 20 11 19 10.9994V9.99937C20 10 21 9 21 8H22C22 9 23 10 24 10ZM8 18.0008V19.0017C9.32502 19.0017 10.2523 19.3301 10.9141 19.7712C11.5821 20.2166 12.0399 20.8175 12.3556 21.4489C12.6732 22.0842 12.837 22.7316 12.9199 23.2286C12.9609 23.4747 12.981 23.6776 12.9908 23.8151C12.9957 23.8837 12.998 23.9355 12.9991 23.9676C12.9996 23.9837 12.9999 23.9948 12.9999 24.0006L13 24.0026C13 24.0022 13 24.0017 14 24.0017C15 24.0017 15 24.0022 15 24.0026L15.0001 24.0006C15.0001 23.9974 15.0002 23.9925 15.0004 23.986C15.0005 23.9809 15.0007 23.9747 15.0009 23.9676C15.002 23.9355 15.0043 23.8837 15.0092 23.8151C15.019 23.6776 15.0391 23.4747 15.0801 23.2286C15.163 22.7316 15.3268 22.0842 15.6444 21.4489C15.9601 20.8175 16.4179 20.2166 17.0859 19.7712C17.7477 19.3301 18.675 19.0017 20 19.0017V18.0008V17.0017C18.675 17.0017 17.7477 16.6733 17.0859 16.2321C16.4179 15.7868 15.9601 15.1858 15.6444 14.5545C15.3268 13.9192 15.163 13.2718 15.0801 12.7748C15.0391 12.5287 15.019 12.3257 15.0092 12.1883C15.0043 12.1197 15.002 12.0679 15.0009 12.0358C15.0004 12.0197 15.0001 12.0086 15.0001 12.0028L15 12.0008C15 12.0012 15 12.0017 14 12.0017C13 12.0017 13 12.0012 13 12.0008L12.9999 12.0028C12.9999 12.0086 12.9996 12.0197 12.9991 12.0358C12.998 12.0679 12.9957 12.1197 12.9908 12.1883C12.981 12.3257 12.9609 12.5287 12.9199 12.7748C12.837 13.2718 12.6732 13.9192 12.3556 14.5545C12.0399 15.1858 11.5821 15.7868 10.9141 16.2321C10.2523 16.6733 9.32502 17.0017 8 17.0017V18.0008Z' fill='%23252533'/%3E%3C/svg%3E%0A") no-repeat center;
      }

      .looking-message {
        display: block;
        margin: 0;
        padding: 0;
        font-size: 14px;
        line-height: 32px;
        white-space: nowrap;
        overflow: hidden;
        color: #414752;
        width: 100%;

        p {
          -webkit-order: 0;
          order: 0;
          -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
          -webkit-align-self: auto;
          align-self: auto;

          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: row;
          flex-direction: row;
          -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
          -webkit-justify-content: flex-start;
          justify-content: flex-start;
          -webkit-align-content: center;
          align-content: center;
          -webkit-align-items: center;
          align-items: center;
          gap: 4px;

          width: 100%;
          margin: 0;
          padding: 0;
        }

        .dotted-loader {
          &::before {
            content: ".";
            animation: show_dotted 1.5s linear infinite;
          }

          &::after {
            content: ".";
            animation: show_dotted 1.5s linear infinite;
            animation-delay: 1s;
          }

          &>i {
            animation: show_dotted 1.5s linear infinite;
            animation-delay: 0.5s;
          }
        }
      }
    }
  }

  // ---

  .chatAI-message {

    * {
      overflow-wrap: break-word;
      margin: 16px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      overflow-wrap: break-word;
      line-height: 1.4;
      font-weight: 600;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      font-size: 24px;
    }

    h2 {
      font-size: 22px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 16px;
    }

    a,
    .link {
      color: #0f62fe;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    ul,
    ol {
      display: block;
      list-style: disc outside none;
      margin: 16px 0;
      padding: 0 0 0 40px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ol {
      list-style-type: decimal;
    }

    li {
      display: list-item;
      margin: 8px 0;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul ul,
    ol ul {
      list-style-type: circle;
    }

    ol ol,
    ul ol {
      list-style-type: lower-latin;
    }

    blockquote {
      padding: 16px;
      border-radius: 4px;
      background: rgba($white, 0.5);
      p {
        &:first-child {
          &::before {
            content: '\201C';
          }
        }
        &:last-child {
          &::after {
            content: '\201D';
          }
        }
      }
    }

    pre {
      padding: 16px;
      border-radius: 4px;
      background: rgba($white, 0.5);
    }

    img {
      display: inline-block;
      vertical-align: top;
      width: auto;
      max-width: 100%;
      height: auto;
    }

    hr {
      border: 0 none;
      background: rgba($black, 0.25);
      height: 1px;
      width: 100%;
      opacity: 1;
    }

    .responsive-table {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

    table {
      width: 100%;
      max-width: 100%;
      border: 1px solid rgba($black, 0.25);
    }
    table > thead > tr > th,
    table > tbody > tr > th,
    table > tfoot > tr > th,
    table > thead > tr > td,
    table > tbody > tr > td,
    table > tfoot > tr > td {
      padding: 8px;
      line-height: 1.4;
      vertical-align: top;
      border-top: 1px solid rgba($black, 0.25);
    }
    table > thead > tr > th {
      vertical-align: bottom;
      border: 0 none;
      background: rgba($white, 0.5);
      &:last-child{
        border-bottom: 1px solid rgba($black, 0.25);
      }
    }
  }

  // ---

  @keyframes show_dotted {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}