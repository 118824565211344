@import "./variables.scss";
@import "./reset.scss";
@import "./scroll.scss";
@import "./btn.scss";
@import "./chat.page.scss";
@import "./answer-references-component.scss";
@import "./loader.scss";
@import "./version.page.scss";

#root,
#root > .content,
#root > .content > .chatPage-body {
  width: 100%;
  height: 100vh;
}


body {
  min-width: auto !important;
}

.alert-inline {
  display: block;
  width: 100%;
  padding: 8px 20px 8px 52px;
  position: relative;
  min-height: 36px;
  margin: 16px 0;

  max-height: 130px;
  overflow-y: auto;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  p {
    display: block;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: 400;
    color: $black;

    &.bold {
      font-weight: 700;
    }
  }

  &.error {
    background: $red-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 10px;
      left: 20px;
      background: transparent
        url("../svg/alert_error.svg")
        no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }

  &.success {
    background: $green-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 10px;
      left: 20px;
      background: transparent url("../svg/alert_success.svg") no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }

  &.info {
    background: $blue-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 10px;
      left: 20px;
      background: transparent url("../svg/alert_info.svg") no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }

  &.warning {
    background: $yellow-200;

    &::before {
      content: "";
      display: block;
      width: 16px;
      height: 16px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 9px;
      left: 20px;
      background: transparent url("../svg/alert_warning.svg") no-repeat scroll 50% 50%;
      background-size: 16px 16px;
    }
  }
}