@import "./variables.scss";

.btn {
  display: inline-block;
  vertical-align: top;
  transition: background-color $trs ease;
  will-change: background-color;
  padding: 7px 15px;
  border-radius: $brs;
  font-weight: $bold;
  color: $black;
  border: 1px solid $gray-200;
  background-color: $white;
  font-size: 14px;
  line-height: 20px;
  width: auto;
  max-width: 100%;
  text-align: center;
  outline: 0 none !important;

  > span {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  .loader-wrapper {
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
  }

  &:hover {
    background-color: $gray-100;
  }

  &:active,
  &:focus-visible {
    border: 1px solid $gray-200;
    box-shadow: 0px 0px 0px 2px rgba($black, 0.2);
    background-color: $white;
    outline: none;
  }

  &:disabled {
    background-color: $white;
    box-shadow: none;
    color: $black;
    cursor: not-allowed !important;
    pointer-events: none !important;
    opacity: 0.5;
  }
}
